@font-face {
font-family: '__clashGrotesk_cc4528';
src: url(/_next/static/media/2da0db5f5e8bd34c-s.p.woff2) format('woff2');
font-display: block;
}@font-face {font-family: '__clashGrotesk_Fallback_cc4528';src: local("Arial");ascent-override: 84.47%;descent-override: 23.73%;line-gap-override: 8.54%;size-adjust: 105.37%
}.__className_cc4528 {font-family: '__clashGrotesk_cc4528', '__clashGrotesk_Fallback_cc4528'
}

@font-face {
font-family: '__clashDisplay_3ca13c';
src: url(/_next/static/media/5860e2bc8e383f94-s.p.woff2) format('woff2');
font-display: block;
}@font-face {font-family: '__clashDisplay_Fallback_3ca13c';src: local("Arial");ascent-override: 77.76%;descent-override: 21.84%;line-gap-override: 7.86%;size-adjust: 114.46%
}.__className_3ca13c {font-family: '__clashDisplay_3ca13c', '__clashDisplay_Fallback_3ca13c'
}.__variable_3ca13c {--font-clash-display: '__clashDisplay_3ca13c', '__clashDisplay_Fallback_3ca13c'
}

@font-face {
font-family: '__satoshi_1b12de';
src: url(/_next/static/media/867bce6efedfde96-s.p.ttf) format('truetype');
font-display: block;
}@font-face {font-family: '__satoshi_Fallback_1b12de';src: local("Arial");ascent-override: 92.36%;descent-override: 21.95%;line-gap-override: 9.14%;size-adjust: 109.35%
}.__className_1b12de {font-family: '__satoshi_1b12de', '__satoshi_Fallback_1b12de'
}.__variable_1b12de {--font-satoshi: '__satoshi_1b12de', '__satoshi_Fallback_1b12de'
}

